
import Vue from 'vue';
import { sync } from 'vuex-pathify';

export default Vue.extend({
  name: 'DocumentationFab',

  data: () => ({ fab: false }),

  computed: {
    ...(sync('snackbar/*') as any)
  },

  methods: {
    onScroll() {
      if (typeof window === 'undefined') return;

      const top = window.pageYOffset || document.documentElement.offsetTop || 0;

      this.fab = top > 300; // should be 300 in case page scrolls down
    },
    toTop() {
      // this.$router.replace(); //clears hash if any
      this.$vuetify.goTo(0);
    }
  }
});
